import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EnvConfiguration} from "@sv/sv-common/lib/env/env.configuration";
import {EnvService} from "@sv/sv-common/lib/env/service/env.service";
import {GogoCmsEnvironmentInterface} from "@sv/sv-common/lib/env/interfaces/GogoCmsEnvironmentInterface";
import {MarketplaceEnvironmentInterface} from "@sv/sv-common/lib/env/interfaces/MarketplaceEnvironmentInterface";
import {CmsEnvironmentInterface} from "@sv/sv-common/lib/env/interfaces/CmsEnvironmentInterface";
import {TeacherEnvironmentInterface} from "@sv/sv-common/lib/env/interfaces/TeacherEnvironmentInterface";
import {SusEnvironmentInterface} from "@sv/sv-common/lib/env/interfaces/SusEnvironmentInterface";
import {GogoEnvironmentInterface} from "@sv/sv-common/lib/env/interfaces/GogoEnvironmentInterface";
import {EnvironmentUnion} from "@sv/sv-common/lib/env/environmentUnion";
import {DeepPartial} from "chart.js/dist/types/utils";

@NgModule({
  imports: [
    CommonModule,
  ],
})
export class EnvModule {

  static forRoot(config: EnvConfiguration): ModuleWithProviders<EnvModule> {
    return this.createEnvironment(config);
  }

  static forGogoBot(config: Partial<GogoEnvironmentInterface> | Partial<GogoCmsEnvironmentInterface>): ModuleWithProviders<EnvModule> {
    return this.createEnvironment(config);
  }

  static forGogoBotCms(config: Partial<GogoCmsEnvironmentInterface>): ModuleWithProviders<EnvModule> {
    return this.createEnvironment(config);
  }

  static forCms(config: Partial<CmsEnvironmentInterface>): ModuleWithProviders<EnvModule> {
    // const modules = this.createEnvironment(config);
    // (modules as any).imports = [];
    // (modules as any).imports.push(...importApiModules(environment));
    // return modules;
    return this.createEnvironment(config);
  }

  static forMarketplace(config: Partial<MarketplaceEnvironmentInterface>): ModuleWithProviders<EnvModule> {
    return this.createEnvironment(config);
  }

  static forTeacher(config: Partial<TeacherEnvironmentInterface>): ModuleWithProviders<EnvModule> {
    return this.createEnvironment(config);
  }

  static forSus(config: Partial<SusEnvironmentInterface>): ModuleWithProviders<EnvModule> {
    return this.createEnvironment(config);
  }

  private static createEnvironment(config: DeepPartial<EnvironmentUnion>): ModuleWithProviders<any> {

    return {
      ngModule: EnvModule,
      providers: [
        {
          provide: EnvConfiguration,
          useValue: config
        },
        EnvService,
      ],
    };
  }
}
