import {CanActivateFn, Router} from '@angular/router';
import {map} from "rxjs";
import {inject} from "@angular/core";
import {IntersimAuthorizedUserService} from "@fr2024/ri-ui/lib/services/intersim-authorized-user.service";

export const authGuard: CanActivateFn = (route, state) => {
  const authorizedUserService = inject(IntersimAuthorizedUserService);
  const router = inject(Router);
  if (state.url && (state.url).indexOf('/auth/login') === -1) {
    sessionStorage.setItem('redirectUrl', state.url);
  }
  return authorizedUserService.tryLogin()
    .pipe(map(response => {
      if (authorizedUserService.isIntersimTeacher() || authorizedUserService.isIntersimSus()) {
        return true;
      } else {
        router.navigateByUrl('/errors/no-teacher-no-student');
        return false;
      }
    }))
};
