import {computed, effect, inject, Injectable, signal, WritableSignal} from '@angular/core';
import {Group, GroupsService, UserModel, UserService as UserServiceIntersim} from "@sv/swagger/intersim/license-system";
import {catchError, forkJoin, map, Observable, of} from "rxjs";
import {FrontFacingUserInfoDto, UserService} from "@sv/swagger/user-backend";
import {Router} from "@angular/router";
import {EnvConfiguration} from "@sv/sv-common/lib/env/env.configuration";

@Injectable({
  providedIn: 'root'
})
export class IntersimAuthorizedUserService {
  ctUserDetails: WritableSignal<FrontFacingUserInfoDto | undefined> = signal(undefined);
  ctUserService = inject(UserService);
  groupsService = inject(GroupsService);
  ctRoles: FrontFacingUserInfoDto.RolesEnum[] = [];
  intersimUser: WritableSignal<UserModel | undefined> = signal(undefined);
  router = inject(Router);
  envConfig = inject(EnvConfiguration);
  isIntersimTeacher = computed(() => {
    const userDetails = this.ctUserDetails();
    return userDetails?.roles?.some(role => ['Intersim-Teacher'].includes(role)) || userDetails?.intersimUserType === 'Normal';
  });
  isIntersimSus = computed(() => {
    return !this.isIntersimTeacher();
  });
  currentClass = signal<Group | undefined>(undefined);
  classes = signal<Group[]>([]);

  constructor() {
    effect(() => {
      const groups = this.classes();
      const groupId = localStorage.getItem('lastSelectedGroup');
      this.currentClass.set(groups.find(group => group.id === groupId) || this.classes()[0])
    }, {allowSignalWrites: true});
  }

  hasOneOfRoles(roles: string[]) {
    return this.ctUserDetails()?.roles?.some(role => roles.includes(role));
  }

  setUser(param: FrontFacingUserInfoDto) {
    this.ctRoles = param.roles || [];
    this.ctUserDetails.set(param);
  }

  tryLogin(): Observable<[FrontFacingUserInfoDto, UserModel, Group[]]> {
    if (this.ctUserDetails()) {
      return of([this.ctUserDetails(), this.intersimUser(), this.classes()]) as any;
    }
    return forkJoin(
      [
        this.ctUserService.getUserInfo(),
        this.groupsService.api4GroupsGet()
      ]
    ).pipe(
      map(([userResponse, groups]): [FrontFacingUserInfoDto, UserModel] => {
        this.setUser(userResponse);
        this.setAvailableClasses(groups);
        return [userResponse, groups] as any;
      }),
      catchError(error => {
        let language = this.extractValidLanguageFromUrl(window.location.href);
        if (language) {
          language = `${language}/`
        }
        const baseElement = document.getElementsByTagName('base') as HTMLCollectionOf<HTMLBaseElement>;
        let baseHref = '';
        if (baseElement && baseElement[0]) {
          baseHref = baseElement[0].href;
        }
        const redirectUrl = (`${baseHref || window.location.origin + "/"}` + `${sessionStorage.getItem('redirectUrl')}`).replace(new RegExp("(?<!http:|https:)//+", "gm"), "/");
        window.location.href = `${window.location.origin}/${this.envConfig.links?.IDP}?ct-redirect-url=${encodeURI(redirectUrl)}`;
        throw new Error();
      })
    ) as any;
  }

  extractValidLanguageFromUrl(url: string): string | null {
    const validLanguages = ['en', 'fr', 'de', 'it'];
    const regex = /\/([a-z]{2})\/?$/;
    const match = url.match(regex);
    if (match && validLanguages.includes(match[1])) {
      return match[1];
    }
    return '';
  }

  setClass(group: Group) {
    localStorage.setItem('lastSelectedGroup', group.id!);
    this.currentClass.set(group);
  }

  private setAvailableClasses(groups: Group[]) {
    this.classes.set(groups);
  }
}
