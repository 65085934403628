import {Injectable} from '@angular/core';
import {EnvConfiguration} from "libs/angular/sv-common/src/lib/env/env.configuration";
import {ExtractedMedia, PageMappingControllerService} from "@sv/swagger/riva-api";
import {Router} from "@angular/router";
import InternalLinkTypeEnumEnum = ExtractedMedia.InternalLinkTypeEnumEnum;

@Injectable()
export class EnvService {

  constructor(
    private envConfiguration: EnvConfiguration,
    private pageMappingService: PageMappingControllerService,
    private router: Router
  ) {
  }

  openInternalLink(entityId: string, entityType: string | InternalLinkTypeEnumEnum, openInNewTab: boolean, projectName?: string | null) {
    switch (entityType) {
      case "PROJECT":
        this.callLink(this.envConfiguration.links?.RIVA_LP + "/home?project-id=" + entityId, openInNewTab);
        break;
      case "LEARNING_PAGE":
        // TODO DS: Create Preview for user
        // this.callLink(this.envConfiguration.links?.RIVA_LP + "/" + entityId, openInNewTab);
        break;
      case "TASK_GROUP":
        this.callLink(`${this.envConfiguration.links?.RIVA_SUS}/sus/tasks/${projectName}/task-group/${entityId}`, openInNewTab);
        break;
      case "LEARNING_CONTAINER":
        // TODO DS: Create Preview for user
        // this.callLink(this.envConfiguration.links?.RIVA_LP + "/" + entityId, openInNewTab);
        break;
      case "USER":
        // TODO DS: Create Preview for user
        break;
      case "PAGE":
        this.pageMappingService.getPageMappingInfoById(entityId).subscribe(val => {
          this.callLink(`${this.envConfiguration.links?.RIVA_LP}/lp/${val.project.name}/material/${val.learningMaterial.id}/${val.learningUnit.id}/${val.learningUnitTab.id}/${val.pageId}`, openInNewTab);
        })
        break;
      default:
        // this.toastrService.info('Internal links for \'' + entityType + "\' are not implemented yet.");
        break;
    }
  }

  private callLink(url: string, openInNewTab: boolean) {
    openInNewTab ? window.open(url, '_blank') : this.router.navigateByUrl(url);
  }
}
