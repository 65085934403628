import {NgModule} from '@angular/core';
import {BUCKET_TOKEN, MediaLibraryEnvConfig, MediaLibraryEnvConfigInterface} from "@sv/sv-common/lib/env/media-library-env.config";
import {MediaLibraryEnvService} from "@sv/sv-common/lib/env/media-library-env.service";
import {ToFilePathPipe} from "../media-library/file-pipes/to-file-path.pipe";


@NgModule({
  declarations: [],
  providers: [
    MediaLibraryEnvService,
    ToFilePathPipe
  ]
})
export class MediaLibraryModalEnvModule {
  static forRoot(config: MediaLibraryEnvConfigInterface) {
    return {
      ngModule: MediaLibraryModalEnvModule,
      providers: [
        {
          provide: MediaLibraryEnvConfig,
          useValue: {
            ...config,
            staticResourceManagerBasePath: config.staticResourceManagerBasePath || '/api-gateway/media-manager/v1'
          },
        },
        {
          provide: BUCKET_TOKEN,
          useValue: config.bucket
        }
      ],
    };
  }
}
