import {Injectable} from '@angular/core';
import {ComponentLoaderService} from "@fr2024/editor-new/lib/editor/services/component-loader.service";
import {EditorTreeRoot} from "@sv/swagger/riva-api";

@Injectable()
export class GgComponentLoaderService extends ComponentLoaderService {

  override getComponent(elementEnum: EditorTreeRoot.ModuleNameEnum) {
    switch (elementEnum) {
      case EditorTreeRoot.ModuleNameEnum.GgIf:
        return import('../../../../gg-editor/src/lib/modules/gg-if-module/gg-if-module.component').then(v => v.GgIfModuleComponent);
      case EditorTreeRoot.ModuleNameEnum.GgSwitch:
        return import('../../../../gg-editor/src/lib/modules/gg-switch-module/gg-switch-module.component').then(v => v.GgSwitchModuleComponent);
      case EditorTreeRoot.ModuleNameEnum.GgFormWrapper:
        return import('../../../../gg-editor/src/lib/modules/form/form-wrapper-module/gg-form-wrapper-module.component').then(v => v.GgFormWrapperModuleComponent);
      case EditorTreeRoot.ModuleNameEnum.GgFormControl:
        return import('../../../../gg-editor/src/lib/modules/form/gg-form-control-module/gg-form-control-module.component').then(v => v.GgFormControlModuleComponent);
      case EditorTreeRoot.ModuleNameEnum.GgPanel:
        return import('../../../../gg-editor/src/lib/modules/gg-panel-module/gg-panel-module.component').then(v => v.GgPanelModuleComponent);
      case EditorTreeRoot.ModuleNameEnum.GgVisibleFormText:
        return import('../../../../gg-editor/src/lib/modules/gg-visible-form-text-module/gg-visible-form-text-module.component').then(v => v.GgVisibleFormTextModuleComponent);
      case EditorTreeRoot.ModuleNameEnum.GgSystemContextText:
        return import('../../../../gg-editor/src/lib/modules/gg-system-context-text-module/gg-system-context-text-module.component').then(v => v.GgSystemContextTextModuleComponent);
      case EditorTreeRoot.ModuleNameEnum.GgListSystemContext:
        return import('../../../../gg-editor/src/lib/modules/gg-list-system-context-module/gg-list-system-context-module.component').then(v => v.GgListSystemContextModuleComponent);
      case EditorTreeRoot.ModuleNameEnum.GgListUserText:
        return import('../../../../gg-editor/src/lib/modules/gg-list-user-text-module/gg-list-user-text-module.component').then(v => v.GgListUserTextModuleComponent);
      case EditorTreeRoot.ModuleNameEnum.GgContentGroup:
        return import('../../../../gg-editor/src/lib/modules/gg-content-group/gg-content-group-module.component').then(v => v.GgContentGroupModuleComponent);
      case EditorTreeRoot.ModuleNameEnum.GgOutputType:
        return import('../../../../gg-editor/src/lib/modules/gg-output-type-module/gg-output-type-module.component').then(v => v.GgOutputTypeModuleComponent);
    }
    return Promise.resolve()
  }

  // override getViewComponent(moduleName: EditorTreeRoot.ModuleNameEnum) {
  //   switch (moduleName) {
  //     case EditorTreeRoot.ModuleNameEnum.GgContentGroup:
  //       return import('../../../../gg-editor/src/lib/viewers/gg-content-group-viewer/gg-content-group-viewer.component').then(v => v.GgContentGroupViewerComponent);
  //     case EditorTreeRoot.ModuleNameEnum.GgFormWrapper:
  //       return import('../../../../gg-editor/src/lib/viewers/gg-form-wrapper-viewer/gg-form-wrapper-viewer.component').then(v => v.GgFormWrapperViewerComponent);
  //     case EditorTreeRoot.ModuleNameEnum.GgFormControl:
  //       return import('../../../../gg-editor/src/lib/viewers/gg-form-control-viewer/gg-form-control-viewer.component').then(v => v.GgFormControlViewerComponent);
  //     case EditorTreeRoot.ModuleNameEnum.GgIf:
  //       return import('../../../../gg-editor/src/lib/viewers/gg-if-viewer/gg-if-viewer.component').then(v => v.GgIfViewerComponent);
  //     case EditorTreeRoot.ModuleNameEnum.GgListUserText:
  //       return import('../../../../gg-editor/src/lib/viewers/gg-list-user-text-viewer/gg-list-user-text-viewer.component').then(v => v.GgListUserTextViewerComponent);
  //     case EditorTreeRoot.ModuleNameEnum.GgSwitch:
  //       return import('../../../../gg-editor/src/lib/viewers/gg-switch-viewer/gg-switch-viewer.component').then(v => v.GgSwitchViewerComponent);
  //     case EditorTreeRoot.ModuleNameEnum.GgVisibleFormText:
  //       return import('@fr2024/editor-new/lib/viewer/viewers/text-viewer/text-viewer.component').then(v => v.TextViewerComponent);
  //   }
  //   return Promise.resolve()
  // }
}
