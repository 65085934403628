import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideRouter, withEnabledBlockingInitialNavigation,} from '@angular/router';
import {appRoutes} from './app.routes';
import {provideHttpClient} from "@angular/common/http";
import {NbDialogModule, NbMenuModule, NbSidebarModule, NbThemeModule, NbToastrModule} from "@nebular/theme";
import '@angular/localize/init';
import {NbEvaIconsModule} from "@nebular/eva-icons";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {
  MediaLibraryModalEnvModule
} from "@fr2024/media-library/lib/nebular/media-library-modal-env/media-library-modal-env.module";
import {FileControllerService} from "@sv/swagger/riva-api";
import {ComponentLoaderService} from "@fr2024/editor-new/lib/editor/services/component-loader.service";
import {
  GgComponentLoaderService
} from "@fr2024/gg-editor/lib/services/gg-component-loader.service";
import {environment} from "../environments/environment";
import {EnvModule} from "@sv/sv-common/lib/env/env.module";
import {mediaManagerProvider} from "@sv/sv-common/lib/api-modules/media-manager.provider";
import {
  gogobotBackendModuleProvider
} from "@sv/sv-common/lib/api-modules/gogobot-backend-module.provider";
import {
  userBackendModuleProvider
} from "@sv/sv-common/lib/api-modules/user-backend-module.provider";

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: ComponentLoaderService,
      useClass: GgComponentLoaderService
    },
    provideHttpClient(),
    importProvidersFrom(
      BrowserAnimationsModule,
      gogobotBackendModuleProvider,
      NbMenuModule.forRoot(),
      NbThemeModule.forRoot(),
      NbEvaIconsModule,
      NbSidebarModule.forRoot(),
      mediaManagerProvider,
      userBackendModuleProvider,
      MediaLibraryModalEnvModule.forRoot({
        bucket: environment.mock.BUCKET,
        multipartFileName: 'file',
        functions: {
          replace: {
            replaceService: FileControllerService,
            renameFunctionName: 'renameFile'
          },
          rename: {
            replaceService: FileControllerService,
            renameFunctionName: 'renameFile'
          }
        }
      }),
      NbToastrModule.forRoot(),
      NbDialogModule.forRoot(),
      EnvModule.forGogoBotCms(environment)
    ),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation())
  ],

};
