import {inject} from '@angular/core';
import {CanActivateFn} from '@angular/router';
import {NbToastrService} from "@nebular/theme";
import {ToastrService} from "ngx-toastr";
import {SvAuthorizedBaseService} from "@sv/sv-common/lib/services/sv-authorized-base.service";

export const roleGuard: CanActivateFn = (route, state) => {
  const possibleRoles = route.data["roles"] as Array<string>;
  const roleProvider = inject(SvAuthorizedBaseService);

  if (!possibleRoles || possibleRoles.some(r => roleProvider.getRoles().indexOf(r) > -1)) {
    return true;
  } else {
    try {
      inject(NbToastrService).warning('You do not have the necessary permission to access this site', 'Error');
    } catch (e) {

    }
    try {
      inject(ToastrService).warning('You do not have the necessary permission to access this site', 'Error');
    } catch (e) {

    }
    return false;
  }
}

