import {ResolveFn} from '@angular/router';
import {SVRoute} from "../helpers/sv-routes";
import {RouteData} from "../helpers/route-data";

export const loadStylesResolver: ResolveFn<boolean> = (route, state) => {
  document.querySelectorAll('link[style-resolver]').forEach((linkWithStyleResolver) => {


    if ((linkWithStyleResolver instanceof HTMLLinkElement)) {
      if ((route.data as RouteData)?.loadStyleFiles?.length) {
        if (!(route as SVRoute).data!.loadStyleFiles!.includes(linkWithStyleResolver.getAttribute('style-resolver')!)) {
          linkWithStyleResolver.remove();
        } else {
          const index = (route as SVRoute).data!.loadStyleFiles!.findIndex(v => v === linkWithStyleResolver.getAttribute('style-resolver'));
          if (index > -1) {
            (route as SVRoute).data!.loadStyleFiles!.splice(index, 1);
          }
        }
      } else {
        linkWithStyleResolver.remove();
      }
    }
  });
  if ((route.data as RouteData)?.loadStyleFiles?.length) {
    const bases = document.getElementsByTagName('base');
    let baseHref = "";

    if (bases.length > 0) {
      baseHref = bases[0].href;
    }
    (route.data as RouteData)?.loadStyleFiles?.forEach(styleToAdd => {
      const link = document.createElement('link');
      link.href = `${baseHref}${styleToAdd}`;
      link.rel = 'stylesheet';
      link.setAttribute('style-resolver', `${styleToAdd}`);
      document.body.appendChild(link);
    });
  }
  return true;
};
