import {Route} from '@angular/router';
import {loadStylesResolver} from "@sv/sv-common/lib/resolvers/load-styles.resolver";
import {authGuard} from "@fr2024/ri-ui/lib/guards/auth.guard";
import {roleGuard} from "@sv/sv-common/lib/guards/role.guard";

export const appRoutes: Route[] = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth-routing.module').then((val) => val.AuthRoutingModule),
  },
  {
    path: 'redirect-oauth',
    loadComponent: () => import('./pages/auth/redirect-oauth/redirect-oauth.component').then(val => val.RedirectOauthComponent)
  },
  {
    path: '',
    loadChildren: () => import('./layout/layout-routing.module').then((val) => val.LayoutRoutingModule),
    canActivate: [authGuard, roleGuard],
    resolve: [loadStylesResolver],
    runGuardsAndResolvers: 'always'
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full'
  },
];
